/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Grid } from 'theme-ui';
import SectionHeader from 'components/section-header';
import FeatureCard from 'components/feature-card.js';
import Marketplace from 'assets/feature/marketplace.svg';
import Gift from 'assets/feature/gift.svg';
import Award from 'assets/feature/award.svg';

const data = [
  {
    id: 1,
    imgSrc: Marketplace,
    altText: 'Marketplace',
    title: 'Trusted marketplace',
    text:
      'Our homes are designed to be move-in ready. Think furnished common areas, super fast wifi, and flexible leases.',
  },
  {
    id: 2,
    imgSrc: Gift,
    altText: 'Gift',
    title: 'Customer Gift vouchers',
    text:
      'Our homes are designed to be move-in ready. Think furnished common areas, super fast wifi, and flexible leases.',
  },
  {
    id: 3,
    imgSrc: Award,
    altText: 'Awards',
    title: 'Multiple Awards wins',
    text:
      'Our homes are designed to be move-in ready. Think furnished common areas, super fast wifi, and flexible leases.',
  },
];

export default function Feature() {
  return (
    <section sx={{ variant: 'section.feature' }} id="feature">
      <Container>
        <SectionHeader
          title="Our portfolio companies:"
          description=""
        />
        <div>
          <h3>Blockchain</h3>
          <ul>
            <li><a target={"_blank"} href={"https://peer2panel.com"}>Peer2Panel</a> - Tokenised solar panels and NFT marketplace.</li>
            <li><a target={"_blank"} href={"https://blockhouse.jonathanlehner.com"}>Blockhouse</a> - Tokenised real estates and mortgages, regulated in Switzerland.</li>
            <li><a target={"_blank"} href={"https://nfcop.com"}>NFCop</a> Platform for trading and licensing copyrights for images, videos and 3D models.</li>
            <li><a target={"_blank"} href={"https://wunderwallet.io"}>Wunderwallet.io</a> - Smart contract wallet focused on NFTs and gaming.</li>
          </ul>
          <h3>AI</h3>
          <p>Our focus is on automation and making people's lives easier.</p>
          <ul>
            <li><a target={"_blank"} href={"https://limepoker.jonathanlehner.com"}>LimePoker</a> - Next-level poker trainer based on the #1 AI in Texas Hold'em.</li>
            <li>Pluribus Research - Hedge fund using RL to trade crypto currencies.</li>
            <li><a target={"_blank"} href={"https://lameda.jonathanlehner.com"}>Lameda</a> - Cross-platform assistant to suggest replies to customer queries based on product data, promotions and FAQs.</li>
            <li><a target={"_blank"} href={"https://grabigo.com"}>Grabigo</a> - Cashierless shop with a kiosk that incorporates Lameda to answer customer questions.</li>
            <li><a target={"_blank"} href={"https://layoutist.com"}>Layoutist.com</a> - Using GPT4 to turn your drawing into a fully-responsive website.</li>
            <li><a target={"_blank"} href={"https://poseteacher.com"}>Lingjing MotionInstructor</a> - Using AI to teach dance moves and physical exercises.</li> 
          </ul>
          <h3>Consulting and web development</h3>
          <p>We completed various client projects. We offer a full range of software development services.</p>
          <ul>
            <li>Web scraping (facebook, comparis, news websites, sneaker websites, apartments)</li>
            <li>Websites / ecommerce / landing pages</li>
            <li><a target={"_blank"} href={"https://matchedbettingbot.com"}>MatchedBettingBot.com</a> - Desktop application to fully automate the matched betting process (RPA).</li>
          </ul>
        </div>
        {/*<Grid sx={styles.grid}>
          {data.map((item) => (
            <FeatureCard
              key={item.id}
              src={item.imgSrc}
              alt={item.title}
              title={item.title}
              text={item.text}
            />
          ))}
          </Grid>*/}
      </Container>
    </section>
  );
}

const styles = {
  grid: {
    pt: [0, null, null, null, null, null, null, null, 4],
    pb: [0, null, null, null, null, null, null, null, 6],
    gridGap: [
      '40px',
      '45px',
      '45px 30px',
      null,
      '60px 30px',
      '50px 40px',
      null,
      '75px',
    ],
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(3,1fr)',
    ],
  },
};
